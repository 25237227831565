import React, { useEffect, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import "aos/dist/aos.css";
import AOS from "aos";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import { Link } from "react-router-dom";
import axios from "axios";
import { api } from "../Constants";

function Ourproducts() {

    useEffect(() => {
        AOS.init({
            duration: 2000,
            once: false,
        });
    }, []);
    const [categoryList, setCategoryList] = useState([]);
    const [selectedCategorySlug, setSelectedCategorySlug] = useState(null);
    const [productDetails, setProductDetails] = useState([]);

    useEffect(() => {

         const apiurl = api + 'category_list';

        axios
            .post(apiurl)
            .then((response) => {
                setCategoryList(response.data.data);
                if (response.data.data.length > 0) {
                    setSelectedCategorySlug(response.data.data[0].slug);
                }
            })
            .catch((error) => {
                console.error("Error fetching category list:", error);
            });
    }, []);

    useEffect(() => {
        if (selectedCategorySlug !== null) {
            axios
                .post("https://unicointl.com/Flash-M/api/search_productcategory", {
                    category: selectedCategorySlug,
                })
                .then((response) => {
                    setProductDetails(response.data.data);
                })
                .catch((error) => {
                    console.error("Error fetching product details:", error);
                });
        }
    }, [selectedCategorySlug]);

    const handleCategoryClick = (slug) => {
        setSelectedCategorySlug(slug);
    };

    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    // var subject = "testing"

    const handleShow = (productCode) => {
        setShow(true);
        // setFormData({ ...formData, product_code: productCode });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange4 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const productcode_details = (vals) => {
        localStorage.removeItem('item_code');
        localStorage.setItem("item_code", vals);
        setproduct_code(vals);
    };

    const [product_code, setproduct_code] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: '',
        product_code,
    });
    const handleClick = () => {
        if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
            fetch(api + 'add_enquirydetails', {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    changeOrigin: true,
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (response.status === 200) {
                        alert("send message succesfully");
                        handleClose();
                        window.location.reload();
                    }
                    else (
                        alert("some error is occured")
                    )
                })
        }
        // document.getElementById("nav").style.border = "1px solid black";
    }



    function onChange(value) {
        var prd_code = localStorage.getItem("item_code");
        setFormData({ ...formData, product_code: prd_code });
        console.log("Captcha value:", value);
    }
    return (
        <section className="pt-40 pb-40 our-product section-space pt-0" data-aos="fade-up">
            <div className="container">
                <div className="row" >
                    <div className="col-12">
                        <div className="section-title text-center">
                            <h2>Our Products</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <Tabs>
                            <TabList>
                                <div className="tab-menu">
                                    <ul className="home-our-product-d-flex justify-content-center">
                                        {categoryList.slice(0, 4).map((category) => (
                                            <li key={category.category_id}>
                                                <Tab>
                                                    <button onClick={() => handleCategoryClick(category.slug)} className={selectedCategorySlug === category.slug ? 'active' : ''}>
                                                        {category.name}
                                                    </button>
                                                </Tab>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                            </TabList>
                            <div className="product-container our-product-home">
                                <div className="tab-content">
                                    <TabPanel>
                                        <div className="row" >
                                            {productDetails.map((product) => (
                                                <div className="col-lg-3 col-md-4 col-sm-6 product-item" key={product.product_id}>
                                                    <figure className="product-thumb">
                                                        <Link to={`/Productdetail/${product.slug}`}>
                                                            <img
                                                                className="pri-img"
                                                                src={product.product_image}
                                                                alt="product"
                                                            />
                                                        </Link>
                                                        <div className="button-group">
                                                            <a
                                                                href={product.product_image}
                                                                data-fancybox="group"
                                                                data-caption={product.name}>
                                                                <span
                                                                    data-bs-toggle="tooltip"
                                                                    data-bs-placement="left"
                                                                    title="Quick View"
                                                                    className="home-ourproduct-svg"><FontAwesomeIcon className="svg" icon={faMagnifyingGlass} />
                                                                </span>
                                                            </a>
                                                        </div>
                                                        <div className="box-cart">
                                                            <button
                                                                type="button"
                                                                className="btn btn-cart"
                                                                onClick={() => {
                                                                    handleShow(); productcode_details(product.name);
                                                                }}
                                                            >
                                                                Enquiry Now
                                                            </button>
                                                        </div>
                                                    </figure>
                                                    <div className="product-caption">
                                                        <div className="product-identity">
                                                            <p className="manufacturer-name">
                                                                <Link to={`/Productdetail/${product.slug}`}>{product.name}</Link>
                                                            </p>
                                                        </div>
                                                        <p className="product-name">
                                                            <Link to={`/Productdetail/${product.slug}`}>
                                                                Model - <span className="product-spech">{product.model}</span>
                                                            </Link>
                                                        </p>
                                                        <p>
                                                            Spec - <span className="product-spech">{product.specification}</span>{" "}
                                                        </p>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </TabPanel>
                                </div>
                            </div>
                        </Tabs>
                    </div>
                </div>
            </div>

            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>For Enquiry Enter Your Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="POST" action='javascript:void(0)'>
                        <div className="row mb3 g-3">
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Your Name"
                                    id='name'
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onKeyPress={(event) => {
                                        if (!/[a-z,A-Z]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required></input>
                            </div>
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange1}
                                    maxLength="10"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required
                                ></input>
                            </div>
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="email" className="form-control" placeholder="Email Address" id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange2}
                                    required
                                ></input>
                            </div>

                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange4}
                                    required
                                ></input>
                            </div>
                            <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange3}
                                required></textarea><small id="messageerror" class="error"></small></div>
                            <div>
                                <div className="col-lg-12 col-md-12 mb20">
                                    <ReCAPTCHA
                                        sitekey={"6Lep_JUpAAAAAOvfRkcPYCa5WmOdfTLZWp3pmS1r"}
                                        Secret Key={"6Lep_JUpAAAAAJP3BdXytx8ga3Fk8a15QZkD6Pew"}
                                        className="g-recaptcha my-3"
                                        onChange={onChange} />
                                </div>
                                <Modal.Footer className='validationpopup-button-sec'>
                                    <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                                    <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                                        setShow(false)
                                    }}>close</button>
                                </Modal.Footer></div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>
    );
}

export default Ourproducts;