import React, { useEffect, useState } from 'react'
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { api } from '../Constants';

function Addbannersec() {
    useEffect(() => {
        AOS.init({
          duration: 1200,
          once: false,
        });
      }, []);


      const [data, setData] = useState([]);

    useEffect(() => {
        const apiUrl = api + 'ads_list';
        axios.post(apiUrl)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Server Error:', error.response.data);
                } else if (error.request) {
                    console.log('No Response Received');
                } else {
                    console.log('Error:', error.message);
                }
            });
    }, []);
      
  return (
    <section class="pt-80 pb-40 banner-statistics section-space">
    <div class="container">
        <div class="row mbn-30">
        {data !== undefined && data.length > 0 ? data.map((item) => {
         
            return(
            <div class="col-md-4">
                <div class="banner-item mb-30">
                    <figure class="banner-thumb" data-aos="fade-up" >
                        <Link to={item.link}>
                            <img src={item.image} alt=""/>
                        </Link>
                        <figcaption class="banner-content">
                            <h3 class="text2">{item.main_title}</h3>
                            <h4 class="text3">{item.title}</h4>
                        </figcaption>
                    </figure>
                </div>
            </div>
            )
        }) : null}
        </div>
    </div>
</section>
  )
}

export default Addbannersec
