import React, { useEffect, useState } from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import logo from "../assets/new-images/logo.png";
import { Link, useLocation } from "react-router-dom";
import { NavLink } from "react-router-dom";

function Header({ categorylist }) {
  const [show, setShow] = useState(false);
  const showDropdown = (e) => {
    setShow(!show);
  };
  const hideDropdown = (e) => {
    setShow(false);
  };

  const [stickyClass, setStickyClass] = useState("");

  useEffect(() => {
    window.addEventListener("scroll", stickNavbar);
    return () => window.removeEventListener("scroll", stickNavbar);
  }, []);

  const stickNavbar = () => {
    if (window !== undefined) {
      let windowHeight = window.scrollY;
      windowHeight > 70
        ? setStickyClass("sticky is-sticky")
        : setStickyClass("");
    }
  };
  const halfLength = Math.ceil(categorylist.length / 2);
  const firstHalf = categorylist.slice(0, halfLength);
  const secondHalf = categorylist.slice(halfLength);


const noneClick = () => {
  let element = document.getElementById("responsive-navbar-nav")
  element.classList.remove("show")
}



  return (
    <div className={`main-header header-main-area ${stickyClass}`}>
      <Navbar collapseOnSelect expand="lg" className="bg-body-tertiary">
        <Container>
          <Navbar.Brand >
          <Link to="/">
            <img src={logo} alt="logo" />
            </Link>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav"/>
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto ms-auto" >
              <Nav.Link href="#features">
                <Link to="/">HOME</Link>{" "}
              </Nav.Link>
              <Nav.Link href="#pricing">
                <Link to="/Aboutus">ABOUT US</Link>{" "}
              </Nav.Link>
              <NavDropdown
                className="main-navdrop"
                title="PRODUCTS"
                id="collasible-nav-dropdown"
                show={show}
                onMouseEnter={showDropdown}
                onMouseLeave={hideDropdown}>
                <div className="row navdropdown">
                  <div className="col-lg-6">
                    {firstHalf.map((list, index) => (
                      <NavDropdown.Item key={index} >
                        <Link to={`/Products/${list.slug}`} onClick={noneClick}>
                          {list.name}
                        </Link>{" "}
                      </NavDropdown.Item>
                    ))}
                  </div>
                  <div className="col-lg-6">
                    {secondHalf.map((list, index) => (
                      <NavDropdown.Item key={index} >
                        <Link to={`/Products/${list.slug}`}>
                          {list.name}
                        </Link>{" "}
                      </NavDropdown.Item>
                    ))}
                  </div>
                </div>
              </NavDropdown>
              <Nav.Link href="#pricing">
                <Link to="/Contactus">CONTACT US </Link>
              </Nav.Link>
            </Nav>
            <Nav className="head-right-quote">
                 <Link to="/Contactus">Get Enquiry</Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </div>
  );
}

export default Header;