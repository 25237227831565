import React, { useEffect, useState } from 'react'
import logo from '../assets/new-images/logo.png'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {faFacebook , faTwitter , faInstagram , faYoutube} from '@fortawesome/free-brands-svg-icons'
import { Link } from 'react-router-dom';
import axios from 'axios';

function Footer({categorylist}) {
  return (
    <footer class="footer-wrapper common-bg">
    <div class="footer-widget-area">
        <div class="container">
            <div class="footer-widget-inner section-space">
                <div class="row mbn-30">
                    <div class="col-lg-3 col-md-6 col-sm-12">
                        <div class="footer-widget-item mb-30">
                            <div class="footer-widget-logo">
                                <Link to="/">
                                    <img src={logo} alt=""/>
                                </Link>
                            </div>
                            <ul class="footer-widget-body footer-cont">
                                <li class="widget-text">Welcome To Flashm Comity. We are one of the top brand international hardware Company. Initially we have started as service Provider in 2010. Then we made progress in 2015 as product Seller.</li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-3 col-md-4 col-sm-12">
                        <div class="footer-widget-item mb-30">
                            <div class="footer-widget-title">
                                <h5>Popular Products</h5>
                            </div>
                            <ul class="footer-widget-body">
                                {categorylist !== undefined && categorylist.length > 0 ? categorylist.slice(0,7).map((list,index)=>{
                                    return(
                                        <div key={index}>
                                             <li><Link to={`/Products/${list.slug}`}>{list.name}</Link></li>
                                        </div>
                                    )
                                }):null}
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-2 col-md-6 col-sm-12">
                        <div class="footer-widget-item mb-30">
                            <div class="footer-widget-title">
                                <h5>Quick Links</h5>
                            </div>
                            <ul class="footer-widget-body">
                                <li><Link to="/">Home</Link></li>
                                <li><Link to="/Aboutus">About Us</Link></li>
                                <li><a href="/Contactus">Contact Us</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-4 col-md-8 col-sm-12">
                        <div class="footer-widget-item mb-30">
                            <div class="footer-widget-title">
                                <h5>Contact Us</h5>
                            </div>
                            <div class="footer-widget-body">
                                <div class="newsletter-inner">
                                    <li class="address">
                                        <em>Address :</em>
                                        <a href="https://www.google.com/maps/search/ID,+Ramaniyam+Pushkar+Phase2,+Sholinganallur,+Chennai-600119./@12.9005415,80.2343705,19z?authuser=0&entry=ttu" target="_blank"> ID, Ramaniyam Pushkar Phase2, Sholinganallur, Chennai-600119.</a>
                                    </li>
                                    <li class="phone">
                                        <em>phones:</em>
                                        <a href="callto:+91 8531951085" class="me-2">+91 8531951085</a>
                                    </li>
                                    <li class="email mail-word-break">
                                        <em>e-mail:</em>
                                        <a href="mailto:info@flashm.co.in" style={{textTransform:"inherit"}}>info@flashm.co.in</a>
                                    </li>
                                    <div class="mailchimp-alerts">
                                        <div class="mailchimp-submitting"></div>
                                        <div class="mailchimp-success"></div>
                                        <div class="mailchimp-error"></div>
                                    </div>
                                </div>
                                <div class="footer-social-link">
                                    <a href="javascript:void(0);" class="facebook" data-bs-toggle="tooltip" title="Facebook"><FontAwesomeIcon icon={faFacebook} /></a>
                                    <a href="javascript:void(0);" class="twitter" data-bs-toggle="tooltip" title="Twitter"><FontAwesomeIcon icon={faTwitter} /></a>
                                    <a href="javascript:void(0);" class="instagram" data-bs-toggle="tooltip" title="Instagram"><FontAwesomeIcon icon={faInstagram} /></a>
                                    <a href="javascript:void(0);" class="youtube" data-bs-toggle="tooltip" title="Youtube"><FontAwesomeIcon icon={faYoutube} /></a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="footer-bottom-area">
        <div class="container">
            <div class="row align-items-center">
                <div class="col-md-6 order-2 order-md-1">
                    <div class="copyright-text">
                        <p>Copyrights &copy; 2024 <b><Link to="/">FlashM</Link></b> by all rights reserved.</p>
                    </div>
                </div>
                <div class="col-md-6 order-1 order-md-2">
                    <div class="payment-method">
                        <p>Developed by <b><a href="https://www.banyaninfotech.com/" target="_blank">The Banyan Infotech.</a></b></p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</footer>
  )
}

export default Footer;
