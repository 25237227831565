import React, { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faMagnifyingGlass, faHouse } from "@fortawesome/free-solid-svg-icons";
import { Link, useLocation } from "react-router-dom";
import Modal from "react-bootstrap/Modal";
import ReCAPTCHA from "react-google-recaptcha";
import axios from "axios";
import { useParams } from "react-router-dom";
import { api } from "../Constants";
import '@fancyapps/fancybox/dist/jquery.fancybox.min.css';


function Products() {

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => { onTop() }, [routePath]);
  const params = useParams();
  const categoryval = params.slugs;

  const [productsList, setProductsList] = useState([]);
  const [heading, setHeading] = useState([]);

  console.log("heading", heading);

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);

  const [showModal, setShowModal] = useState(false);
  useEffect(() => {
    axios
      .post("https://unicointl.com/Flash-M/api/search_productcategory", {
        category: categoryval,
      })
      .then((res) => {
        setProductsList(res.data.data);
        setHeading(res.data.data[0])
      })
      .catch((error) => {
        console.log(error);
      });
  }, [categoryval]);


  var subject = "testing"

  const handleShow = (productCode) => {
    setShow(true);
    setFormData({ ...formData, product_code: productCode });
  }

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange1 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange2 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange3 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };
  const handleChange4 = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };


  const productcode_details = (vals) => {
    localStorage.removeItem('item_code');
    localStorage.setItem("item_code", vals);
    setproduct_code(vals);
  };
   const [product_code,setproduct_code]= useState('')
      
      const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: '',
        product_code,
      });


      function onChange(value) {
        var prd_code = localStorage.getItem("item_code");
        setFormData({ ...formData, product_code: prd_code });
        console.log("Captcha value:", value);
      }



  const handleClick = () => {
    console.log(formData)

    if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
      fetch(api + 'add_enquirydetails', {
        method: "POST",
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json;charset=UTF-8",
          changeOrigin: true,
        },
        body: JSON.stringify(formData),
      })
        .then(response => {
          if (response.status === 200) {
            alert("send message succesfully");
            handleClose();
            window.location.reload();
          }
          else (
            alert("some error is occured")
          )
        })
    }
  }

  return (
    <div className="product-breadcrump">
      <div class="breadcrumb-area" style={{ backgroundImage: `url(${heading.breadcrumb})` }}>
        <div class="container">
          <div class="row">
            <div class="col-12">
              <div class="breadcrumb-wrap">
                <nav aria-label="breadcrumb">
                  <h1>{heading.category_name}</h1>
                  <ul class="breadcrumb">
                    <li class="breadcrumb-item"><Link to='/'><FontAwesomeIcon icon={faHouse} /></Link></li>
                    <li class="breadcrumb-item active" aria-current="page">{heading.category_name}</li>
                  </ul>
                </nav>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="pt-80 pb-80 contact-area section-space">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 product-page">
              <h4>{heading.category_name}</h4>
            </div>
          </div>
          <div className="row product-page-top">
            {productsList !== undefined && productsList.length > 0 ? (
              productsList.map((item, index) => (
                <div
                  className="col-lg-4 col-md-6 col-sm-6 margin-bottom product-item"
                  key={index}>
                  <figure className="product-thumb">
                    <Link to={`/Productdetail/${item.slug}`}>
                      <img
                        className="pri-img"
                        src={item.product_image}
                        alt={item.name} />
                    </Link>
                    <div className="button-group">
                      <a data-fancybox="gallery"
                        href={item.product_image}
                        data-caption={item.name}>
                        <span
                          data-bs-toggle="tooltip"
                          data-bs-placement="left"
                          title="Quick View">
                          <FontAwesomeIcon icon={faMagnifyingGlass} />
                        </span>
                      </a>
                    </div>
                    <div className="box-cart">
                      <button
                        type="button"
                        className="btn btn-cart"
                        onClick={() => {
                          handleShow(); productcode_details(item.name);
                        }}>
                        Enquiry Now
                      </button>
                    </div>
                  </figure>
                  <div className="product-caption">
                    <div className="product-identity">
                      <p className="manufacturer-name">

                        <Link to={`/Productdetail/${item.slug}`}>{item.name}</Link>
                      </p>
                    </div>
                    <p className="product-name">
                      <Link to={`/Productdetail/${item.slug}`}>Model : <span className="product-spech">{item.model}</span>
                      </Link>
                    </p>
                    <p>
                      Spec :
                      <span className="product-spech">
                        {item.specification}
                      </span>
                    </p>
                  </div>
                </div>
              ))
            ) : (
              <div>No products available</div>
            )}
          </div>
        </div>
      </div>




      <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>For Enquiry Enter Your Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form method="POST" action='javascript:void(0)'>
            <div className="row mb3 g-3">
              <div className="col-lg-12 col-md-12 mb20">
                <input type="text" className="form-control" placeholder="Your Name"
                  id='name'
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  onKeyPress={(event) => {
                    if (!/[a-z,A-Z]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required></input>
              </div>
              <div className="col-lg-12 col-md-12 mb20">
                <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                  name="mobile"
                  value={formData.mobile}
                  onChange={handleChange1}
                  maxLength="10"
                  onKeyPress={(event) => {
                    if (!/[0-9]/.test(event.key)) {
                      event.preventDefault();
                    }
                  }}
                  required
                ></input>
              </div>
              <div className="col-lg-12 col-md-12 mb20">
                <input type="email" className="form-control" placeholder="Email Address" id="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange2}
                  required
                ></input>
              </div>

              <div className="col-lg-12 col-md-12 mb20">
                <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                  name="city"
                  value={formData.city}
                  onChange={handleChange4}
                  required
                ></input>
              </div>
              <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                name="message"
                value={formData.message}
                onChange={handleChange3}
                required
              ></textarea><small id="messageerror" class="error"></small></div>
              <div>
                <div className="col-lg-12 col-md-12 mb20">
                  <ReCAPTCHA
                  sitekey={"6Lep_JUpAAAAAOvfRkcPYCa5WmOdfTLZWp3pmS1r"}
                  Secret Key={"6Lep_JUpAAAAAJP3BdXytx8ga3Fk8a15QZkD6Pew"}
                    className="g-recaptcha my-3"
                    onChange={onChange}
                  />
                </div>
                <Modal.Footer className='validationpopup-button-sec'>
                  <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                  <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                    setShow(false)
                  }}>close</button>
                </Modal.Footer></div>
            </div>
          </form>
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Products;