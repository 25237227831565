import React from 'react'
import Productdetail from './Productdetail'


function Productdetailmain() {


  

  return (
    <div>
      <Productdetail/>
    </div>
  )
}

export default Productdetailmain
