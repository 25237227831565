import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import { api } from '../Constants';
import axios from 'axios';

function Categoryproduct() {
    useEffect(() => {
        AOS.init({
            duration: 1800,
            once: false,
        });
    }, []);
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowRight} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    nav: false,
                    arrows: false,
                }

            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };
    const [categorylist, setCategorylist] = useState([]);
    useEffect(() => {
        axios.post(api + "category_list")
            .then((response) => {
                setCategorylist(response.data.data);
            })
            .catch((error) => {
                console.log('Error: in category home banner', error);
            });
    }, []);
    return (
        <section class="pt-40 pb-40 trending-products">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <Slider {...settings} class="category-slider product-carousel--4 slick-row-15 slick-sm-row-10 slick-arrow-style category-carosel">
                            {categorylist !== undefined && categorylist.length > 0 ? categorylist.map((item) => {
                                return (
                                    <div class="product-item" data-aos="fade-up">
                                        <figure class="product-thumb">
                                            <Link to={`/Products/${item.slug}`}>
                                                <img class="pri-img" src={item.image} alt="product" />
                                            </Link>
                                            <div class="button-group">
                                                <a href={item.image} data-fancybox="group" data-caption={item.name}><span data-bs-toggle="tooltip" data-bs-placement="left" title="Full View"><FontAwesomeIcon icon={faMagnifyingGlass} /></span></a>
                                            </div>
                                        </figure>
                                        <div class="product-caption category-caption">
                                            <div class="product-identity">
                                                <p class="manufacturer-name"><Link to={`/Products/${item.slug}`}>{item.name} </Link></p>
                                            </div>
                                        </div>
                                    </div>
                                )
                            }) : null}
                        </Slider>
                    </div>
                </div>
            </div>
        </section>
    )
}

export default Categoryproduct;