import React, { useEffect, useState } from 'react'
import Carousel from 'react-bootstrap/Carousel';
import 'aos/dist/aos.css';
import axios from 'axios';
import AOS from 'aos';
import { api } from '../Constants';
import { Link } from 'react-router-dom';

function Bannersec() {
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: false,
        });
    }, []);


    const [data, setData] = useState([]);
    useEffect(() => {
        const apiUrl = api + 'banner_list';
        axios.post(apiUrl)
            .then((response) => {
                setData(response.data.data);
                if (response.data.data !== undefined && response.data.data.length > 0) {
                }
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Server Error:', error.response.data);
                } else if (error.request) {
                    console.log('No Response Received');
                } else {
                    console.log('Error:', error.message);
                }
            });
    }, []);

    return (
        <div className='home-banner-sec'>
            <Carousel fade>
                {data !== undefined && data.length > 0 ? data.map((item) => {
                    return (
                        <Carousel.Item>
                            <img src={item.image}/>
                            <Carousel.Caption>
                                <h4 data-aos="zoom-in-left">{item.title}</h4>
                                <h1 data-aos="zoom-in-left"><span dangerouslySetInnerHTML={{ __html: item.description }}></span></h1>
                                <Link to={item.link} data-aos="zoom-in-left" class="btn-hero">read more</Link>
                            </Carousel.Caption>
                        </Carousel.Item>
                    )
                }) : null}
            </Carousel>
        </div>
    )
}

export default Bannersec
