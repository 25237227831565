import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faHouse } from '@fortawesome/free-solid-svg-icons'
import { Link } from 'react-router-dom'

function Breadcrumps(props) {
  return (
    <div class={props.classname}>
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="breadcrumb-wrap">
                    <nav aria-label="breadcrumb">
                        <h1>{props.nav}</h1>
                        <ul class="breadcrumb">
                            <li class="breadcrumb-item"><Link to='/'><FontAwesomeIcon icon={faHouse} /></Link></li>
                            <li class="breadcrumb-item active" aria-current="page">{props.nav}</li>
                        </ul>
                    </nav>
                </div>
            </div>
        </div>
    </div>
</div>
  )
}
export default Breadcrumps
