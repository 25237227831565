import React, { useEffect } from 'react'
import Bannersec from './Bannersec'
import Addbannersec from './Addbannersec'
import Trendingproduct from './Trendingproduct'
import Categoryproduct from './Categoryproduct'
import Ourproducts from './Ourproducts'
import { useLocation } from 'react-router-dom';


function Home() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);


  return (
    <div>
      <Bannersec/>
      <Addbannersec/>
      <Trendingproduct/>
      <Categoryproduct/>
      <Ourproducts/>
     
    </div>
  )
}

export default Home
