import React, { useEffect } from 'react'
import Products from './Products'
import { useLocation } from 'react-router-dom';

function Productmain() {

  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  }
  useEffect(() => {
    onTop()
  }, [routePath]);

  return (
    <div>
      <Products/>
    </div>
  )
}
export default Productmain
