import './App.css';
import './assets/css/style.css'
import './assets/css/responsive.css';
import Header from './Header/Header';
import Home from './Homepage/Home';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Footer from './Footer/Footer';
import About from './Aboutpage/About';
import Contact from './contactpage/Contact';
import Productmain from './Products/Productmain';
import Productdetailmain from './Productdetails/Productdetailmain';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleUp } from '@fortawesome/free-solid-svg-icons';
import ScrollToTop from 'react-scroll-to-top';
import { useEffect, useState } from 'react';
import axios from 'axios';
import { api } from './Constants';
function App() {
  const [categorylist, setCategorylist] = useState([]);
   
  
  useEffect(() => {
   
    const apiurl = api + 'category_list';


    axios
      .post(apiurl)

      .then((res) => {
        setCategorylist(res.data.data);
      })
      .catch((err) => {
        console.log(`the error is ${err}`);
      });
  }, []);
  return (
    <div>
    <BrowserRouter basename='/'>
   
    <Header categorylist={categorylist}/>
      <ScrollToTop smooth component={<FontAwesomeIcon icon={faAngleUp}></FontAwesomeIcon>} className="scroll icon" /> 
        <Routes>
          <Route path="/" element={<Home/>}></Route>
          <Route path="/Aboutus" element={<About/>}></Route>
          <Route path="/Contactus" element={<Contact/>}></Route>
          <Route path="/Products/:slugs" element={<Productmain/>}></Route>
          <Route path="/Productdetail/:slug_name" element={<Productdetailmain/>}></Route>
        </Routes>
        <Footer categorylist={categorylist} />
      </BrowserRouter>
    </div>
  );
}

export default App;
