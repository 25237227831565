import React, { useEffect } from "react";
import Breadcrumps from "../breadcrumps/Breadcrumps";
import Formsec from "./Formsec";
import Contactmap from "./Contactmap";
import { useLocation } from "react-router-dom";

function Contact() {
  const routePath = useLocation();
  const onTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    onTop();
    goToTop();
  }, [routePath]);


  const goToTop = () => {
    window.scrollTo({
        top: 200,
        behavior: 'smooth',
    });
};

  return (
    <div>
      <Breadcrumps
        nav="Contact Us"
        classname="breadcrumb-area contact-breadcrump"/>
      <Formsec />
      <Contactmap />
    </div>
  );
}

export default Contact;
