import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faArrowLeft, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons'
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import 'aos/dist/aos.css';
import AOS from 'aos';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { api } from '../Constants';

function Trendingproduct() {
    useEffect(() => {
        AOS.init({
            duration: 1600,
            once: false,
        });
    }, []);

    // var subject = "testing";

    const handleShow = (productCode) => {
        setShow(true);
        setFormData({ ...formData, product_code: productCode });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange4 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };

    const productcode_details = (vals) => {
      
        setproduct_code(vals);
    };

    const [product_code, setproduct_code] = useState('');

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: '',
        product_code,
    });


    function onChange(value) {
        var prd_code = localStorage.getItem("item_code");
        setFormData({ ...formData, product_code: prd_code });
        console.log("Captcha value:", value);
    }

    const handleClick = () => {

        if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {

            fetch(api + 'add_enquirydetails', {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    changeOrigin: true,
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (response.status === 200) {
                        alert("send message succesfully");
                        handleClose();
                        window.location.reload();
                    }
                    else (
                        alert("some error is occured")
                    )
                })
        }
    }

    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowRight} />,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [data, setData] = useState([]);
    useEffect(() => {
        const apiUrl = api + 'select_featuredproduct';
        axios.post(apiUrl)
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    console.log('Server Error:', error.response.data);
                } else if (error.request) {
                    console.log('No Response Received');
                } else {
                    console.log('Error:', error.message);
                }
            });
    }, []);


    return (
        <section class="pt-40 pb-40 trending-products section-space" >
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <div class="section-title text-center">
                            <h2>New Trending Products</h2>
                        </div>
                    </div>
                </div>
                <div class="row">
                    <div class="col-lg-12">
                        <Slider {...settings} class="product-carousel--4 slick-row-15 slick-sm-row-10 slick-arrow-style">
                            {data !== undefined && data.length > 0 ? data.map((item) => (
                                <div class="product-item" data-aos="fade-up">
                                    <figure class="product-thumb">
                                        <Link to={`/Productdetail/${item.slug}`}>
                                            <img class="pri-img" src={item.product_image} alt="product" />
                                        </Link>
                                        <div class="button-group">
                                            <a href={item.product_image} data-fancybox="group" data-caption={item.name}><span data-bs-toggle="tooltip" data-bs-placement="left" title="Full View"><FontAwesomeIcon icon={faMagnifyingGlass} /></span></a>
                                        </div>
                                        <div class="box-cart">
                                            <button type="button" class="btn btn-cart" onClick={() => {
                                                handleShow(); productcode_details(item.name);
                                            }}>Enquiry Now</button>
                                        </div>
                                    </figure>
                                    <div class="product-caption">
                                        <div class="product-identity">
                                            <p class="manufacturer-name"><Link to={`/Productdetail/${item.slug}`}>{item.name} </Link></p>
                                        </div>
                                        <p class="product-name">
                                            <Link to={`/Productdetail/${item.slug}`}>Model - <span class="product-spech"> {item.model} </span> </Link>
                                        </p>
                                        <p>Spec - <span class="product-spech">{item.specification}</span></p>
                                    </div>
                                </div>
                            ))
                                : null}
                        </Slider>
                    </div>
                </div>
            </div>


            <Modal show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>For Enquiry Enter Your Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <form method="POST" action='javascript:void(0)'>
                        <div className="row mb3 g-3">
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Your Name"
                                    id='name'
                                    name="name"
                                    value={formData.name}
                                    onChange={handleChange}
                                    onKeyPress={(event) => {
                                        if (!/[a-z,A-Z]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required></input>
                            </div>
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                                    name="mobile"
                                    value={formData.mobile}
                                    onChange={handleChange1}
                                    maxLength="10"
                                    onKeyPress={(event) => {
                                        if (!/[0-9]/.test(event.key)) {
                                            event.preventDefault();
                                        }
                                    }}
                                    required
                                ></input>
                            </div>
                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="email" className="form-control" placeholder="Email Address" id="email"
                                    name="email"
                                    value={formData.email}
                                    onChange={handleChange2}
                                    required
                                ></input>
                            </div>

                            <div className="col-lg-12 col-md-12 mb20">
                                <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                                    name="city"
                                    value={formData.city}
                                    onChange={handleChange4}
                                    required
                                ></input>
                            </div>
                            <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                                name="message"
                                value={formData.message}
                                onChange={handleChange3}
                                required
                            ></textarea><small id="messageerror" class="error"></small></div>
                            <div>
                                <div className="col-lg-12 col-md-12 mb20">
                                    <ReCAPTCHA
                                    sitekey={"6Lep_JUpAAAAAOvfRkcPYCa5WmOdfTLZWp3pmS1r"}
                                    Secret Key={"6Lep_JUpAAAAAJP3BdXytx8ga3Fk8a15QZkD6Pew"}
                                        className="g-recaptcha my-3"
                                        onChange={onChange}
                                    />
                                </div>
                                <Modal.Footer className='validationpopup-button-sec'>
                                    <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                                    <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                                        setShow(false)
                                    }}>close</button>
                                </Modal.Footer></div>
                        </div>
                    </form>
                </Modal.Body>
            </Modal>
        </section>
    )
}
export default Trendingproduct
