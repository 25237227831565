import React, { useEffect } from 'react'
import Whoweare from './Whoweare'
import Breadcrumps from '../breadcrumps/Breadcrumps'
import { useLocation } from 'react-router-dom';

function About() {

  const routePath = useLocation();
  const onTop = () => {
      window.scrollTo(0, 0);
  }
  useEffect(() => { onTop() }, [routePath]);
  return (
    <div>
    <Breadcrumps nav="About Us" classname = "breadcrumb-area about-breadcrump"/>
    <Whoweare/> 
    </div>
  )
}

export default About
