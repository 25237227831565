import React, { useEffect, useState } from 'react'
import Slider from 'react-slick';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Modal from 'react-bootstrap/Modal';
import ReCAPTCHA from "react-google-recaptcha";
import 'aos/dist/aos.css';
import AOS from 'aos';
import axios from 'axios';
import { faArrowRight, faArrowLeft, faMagnifyingGlass, faHouse } from '@fortawesome/free-solid-svg-icons'
import { Link, useLocation, useParams } from 'react-router-dom';
import { api } from '../Constants';


function Productdetail() {
    const routePath = useLocation();
    const onTop = () => {
        window.scrollTo(0, 0);
    }
    useEffect(() => { onTop() }, [routePath]);

    const params = useParams();
    var prd_slugname = params.slug_name;
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: false,
        });
    }, []);
    var settings = {
        dots: false,
        infinite: true,
        speed: 300,
        slidesToShow: 4,
        slidesToScroll: 4,
        autoplay: true,
        autoplaySpeed: 3000,
        arrows: true,
        prevArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowLeft} />,
        nextArrow: <FontAwesomeIcon className='trending-nav' icon={faArrowRight} />,
        loop: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    infinite: true,
                    dots: false
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 1,
                    arrows: false,
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    arrows: false,
                }
            }
        ]
    };


    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);

    const [data, setData] = useState([]);
    useEffect(() => {
        axios.post(api + 'search_slugproduct', { slug: prd_slugname })
            .then((response) => {
                setData(response.data.data);
            })
            .catch((error) => {
                if (error.response) {
                    // console.log(error.response)
                } else if (error.request) {
                    // console.log(error.request)
                } else {
                    // console.log("error")
                }
            });
    }, [params]);




    var subject = "testing"

    const handleShow = (productCode) => {
        setShow(true);
        setFormData({ ...formData, product_code: productCode });
    }


    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange1 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange2 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange3 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleChange4 = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };



    const productcode_details = (vals) => {
        localStorage.removeItem('item_code');
        localStorage.setItem("item_code", vals);
        setproduct_code(vals);
    };

    const [product_code, setproduct_code] = useState('')

    const [formData, setFormData] = useState({
        name: '',
        mobile: '',
        email: '',
        city: '',
        message: '',
        product_code,
    });


    function onChange(value) {
        var prd_code = localStorage.getItem("item_code");
        setFormData({ ...formData, product_code: prd_code });
        console.log("Captcha value:", value);
    }

    const handleClick = () => {
        if (!formData.name == "" && !formData.mobile == "" && !formData.email == "" && !formData.city == "" && !formData.message == "") {
            console.log(formData);
            fetch(api + 'add_enquirydetails', {
                method: "POST",
                headers: {
                    Accept: "application/json",
                    "Content-Type": "application/json;charset=UTF-8",
                    changeOrigin: true,
                },
                body: JSON.stringify(formData),
            })
                .then(response => {
                    if (response.status === 200) {
                        alert("send message succesfully");
                        handleClose();
                        window.location.reload();
                    }
                    else (
                        alert("some error is occured")
                    )
                })
        }
    }

    return (
        <div>
            {data !== undefined && data.length > 0 ? data.map((item) => {
                return (
                    <div className='product-breadcrump'>
                        <div class="breadcrumb-area" style={{ backgroundImage: `url(${item.breadcrumb})` }}>
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="breadcrumb-wrap">
                                            <nav aria-label="breadcrumb">
                                                <h1>{item.name}</h1>
                                                <ul class="breadcrumb">
                                                    <li class="breadcrumb-item"><Link to='/'><FontAwesomeIcon icon={faHouse} /></Link></li>
                                                    <li class="breadcrumb-item active" aria-current="page">{item.name}</li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="pt-80 pb-40 shop-main-wrapper section-space">
                            <div class="container">
                                <div class="row">
                                    <div class="col-lg-12 order-1 order-lg-2">
                                        <div class="product-details-inner">
                                            <div class="row">
                                                <div class="col-lg-5 product-desk-view">
                                                    <div class="product-large-slider" data-aos="fade-right">
                                                        <div class="pro-large-img ">
                                                            <img src={item.product_image} alt="product-details" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-lg-7">
                                                    <div class="product-details-des" data-aos="fade-left">
                                                        <div class="manufacturer-name">
                                                            <a>Product</a>
                                                        </div>
                                                        <h3 class="product-name">{item.name}</h3>
                                                        <div class="product-mob-view pro-large-img">
                                                            <img src={item.product_image} alt="product-details" />
                                                        </div>
                                                        <p class="pro-desc"><span dangerouslySetInnerHTML={{ __html: item.description }}></span></p>
                                                        <table class="product-spec-mb">
                                                            <tr>
                                                                <td colspan="1">
                                                                    <p class="manufacturer-name prod-detcode-color">Product &nbsp;</p>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <p class="manufacturer-name prod-detcode-color product-spech"> <span data-bs-toggle="tooltip" title="Mother Board ">&nbsp; {item.name}</span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1">
                                                                    <p class="manufacturer-name prod-detcode-color">Quantity &nbsp;</p>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <p class="manufacturer-name prod-detcode-color product-spech"> <span data-bs-toggle="tooltip" title="1 Piece">&nbsp; 1 Piece </span> </p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1">
                                                                    <p class="manufacturer-name prod-detcode-color">Model &nbsp;</p>
                                                                </td>
                                                                <td>:</td>
                                                                <td>
                                                                    <p class="manufacturer-name prod-detcode-color product-spech">&nbsp; {item.model}</p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1">
                                                                    <p class="manufacturer-name prod-detcode-color d-flex">Specification</p>
                                                                </td>
                                                                <td>
                                                                    <div>:</div>
                                                                </td>
                                                                <td>
                                                                    <p class="manufacturer-name prod-detcode-color d-flex "><span class="mx-1">
                                                                        <p data-bs-toggle="tooltip" title="3RD GEN WITH NVME" class="product-spech">{item.specification}</p>
                                                                    </span></p>
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td colspan="1"> </td>
                                                                <td> </td>
                                                                <td> </td>
                                                            </tr>
                                                        </table>
                                                        <div class="quantity-cart-box d-flex align-items-center product-top-margin">
                                                            <div class="action_link">
                                                                <a class="btn btn-cart2" onClick={() => {
                                                                    handleShow(); productcode_details(item.name);
                                                                }}>Enquiry Now</a>
                                                                <Link class="btn btn-cart2 product-det-btn" to="/Contactus">Contact Us</Link>
                                                            </div>
                                                        </div>
                                                        <p class="pro-desc">Images are representative purpose only. The actual  product image may vary.</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {item.related ? <section class="pt-40 pb-80 trending-products section-space">
                            <div class="container">
                                <div class="row">
                                    <div class="col-12">
                                        <div class="section-title text-center">
                                            <h2>Related Products</h2>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">

                                    <div class="col-lg-12">
                                        <Slider {...settings} class="product-carousel--4 slick-row-15 slick-sm-row-10 slick-arrow-style">
                                            {item.related !== undefined && item.related.length > 0 ? item.related.map((st) => {
                                                return (
                                                    <div class="product-item" data-aos="fade-up">
                                                        <figure class="product-thumb">
                                                            <Link to={`/Productdetail/${st.slug}`}>
                                                                <img class="pri-img" src={st.product_image} alt="product" />
                                                            </Link>
                                                            <div class="button-group">
                                                                <a href={st.product_image} data-fancybox="group" data-caption={st.name}><FontAwesomeIcon icon={faMagnifyingGlass} /></a>
                                                            </div>
                                                            <div class="box-cart">
                                                                <button type="button" class="btn btn-cart" onClick={() => {
                                                                    handleShow(); productcode_details(st.name);
                                                                }}>Enquiry Now</button>
                                                            </div>
                                                        </figure>
                                                        <div class="product-caption">
                                                            <div class="product-identity">
                                                                <p class="manufacturer-name"><Link to="/Products">{st.name}</Link></p>
                                                            </div>
                                                            <p class="product-name">
                                                                <Link to="/Productdetail">Model - <span class="product-spech"> {st.model} </span> </Link>
                                                            </p>
                                                            <p>Spec - <span class="product-spech"> {st.specification} </span></p>
                                                        </div>
                                                    </div>
                                                )
                                            }) : null}
                                        </Slider>
                                    </div>
                                </div>
                            </div>
                        </section> : ''}



                        <Modal show={show} onHide={handleClose}>
                            <Modal.Header closeButton>
                                <Modal.Title>For Enquiry Enter Your Details</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <form method="POST" action='javascript:void(0)'>
                                    <div className="row mb3 g-3">
                                        <div className="col-lg-12 col-md-12 mb20">
                                            <input type="text" className="form-control" placeholder="Your Name"
                                                id='name'
                                                name="name"
                                                value={formData.name}
                                                onChange={handleChange}
                                                onKeyPress={(event) => {
                                                    if (!/[a-z,A-Z]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                required></input>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb20">
                                            <input type="text" className="form-control" placeholder="Phone Number" id="mobile"
                                                name="mobile"
                                                value={formData.mobile}
                                                onChange={handleChange1}
                                                maxLength="10"
                                                onKeyPress={(event) => {
                                                    if (!/[0-9]/.test(event.key)) {
                                                        event.preventDefault();
                                                    }
                                                }}
                                                required
                                            ></input>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb20">
                                            <input type="email" className="form-control" placeholder="Email Address" id="email"
                                                name="email"
                                                value={formData.email}
                                                onChange={handleChange2}
                                                required
                                            ></input>
                                        </div>
                                        <div className="col-lg-12 col-md-12 mb20">
                                            <input type="text" className="form-control" placeholder="Enter Your City" id="city"
                                                name="city"
                                                value={formData.city}
                                                onChange={handleChange4}
                                                required
                                            ></input>
                                        </div>
                                        <div class="col-lg-12 col-sm-12 mb20"><textarea type="Message" class="form-control" placeholder="Message" id="message"
                                            name="message"
                                            value={formData.message}
                                            onChange={handleChange3}
                                            required
                                        ></textarea><small id="messageerror" class="error"></small></div>
                                        <div>
                                            <div className="col-lg-12 col-md-12 mb20">
                                                <ReCAPTCHA
                                                    sitekey={"6Lep_JUpAAAAAOvfRkcPYCa5WmOdfTLZWp3pmS1r"}
                                                    Secret Key={"6Lep_JUpAAAAAJP3BdXytx8ga3Fk8a15QZkD6Pew"}
                                                    className="g-recaptcha my-3"
                                                    onChange={onChange}
                                                />
                                            </div>
                                            <Modal.Footer className='validationpopup-button-sec'>
                                                <button className="main-btn mr2" download="" onClick={handleClick}>Submit</button>
                                                <button type="close" className="main-btn ml2 closebtn" data-dismiss="modal" onClick={() => {
                                                    setShow(false)
                                                }}>close</button>
                                            </Modal.Footer></div>
                                    </div>
                                </form>
                            </Modal.Body>
                        </Modal>


                    </div>
                )

            }) : null}
        </div>
    )
}

export default Productdetail;
