import React, { useEffect } from 'react'
import imageone  from '../assets/new-images/about-images/online-store.jpg'
import 'aos/dist/aos.css';
import AOS from 'aos';


function Whoweare() {
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: false,
        });
    }, []);
  return (
    <div class="pb-80  about-us-wrapper section-space">
    <div class="container">
        <div class="row">
            <div class="col-xl-6 col-lg-6">
                <div class="about-text-wrap" data-aos="fade-right">
                    <h2 class="tittle-line about-align-center">Who We Are</h2>

                    <div class="about-image-wrap about-image-mob-view pb-5">
                        <img src={imageone} alt="About" />
                    </div>
                    <p>Flash-M was started in 2010 with the primary objective of offering products and services in the field of Information Technology.</p>

                    <p>Flash-M has established itself as one of the most reputed organisations in Coimbathore in the field of IT, Telecom and Office Automation. We are associated with the leaders in the industry for each one of our product lines.</p>

                    <p>Flash-M has a tradition of striving for excellence in all its initiatives and has been successful in achieving it with flying colours. We have strong presence in various segments such as IT, ITES, Corporate Sector, Education, Government, Defence and Banking.</p>

                </div>
            </div>
            <div class="col-xl-5 col-lg-6 ml-auto about-image-desk-view">
                <div class="about-image-wrap" data-aos="fade-left">
                    <img src={imageone} alt="About" />
                </div>
            </div>
        </div>
    </div>
</div>
  )
}

export default Whoweare
