import React, { useEffect } from 'react'
import 'aos/dist/aos.css';
import AOS from 'aos';

function Contactmap() {
  useEffect(() => {
    AOS.init({
      duration: 1200,
      once: false,
    });
  }, []);
  return (
    <main>
      <div class="pt-40 pb-80">
        <div class="container">
          <div class="col-lg-12" data-aos="fade-up">
            <iframe src="https://www.google.com/maps/embed?pb=!1m12!1m8!1m3!1d972.2779130357072!2d80.2343705!3d12.9005415!3m2!1i1024!2i768!4f13.1!2m1!1sID%2C%20Ramaniyam%20Pushkar%20Phase2%2C%20Sholinganallur%2C%20Chennai-600119.!5e0!3m2!1sen!2sin!4v1709120087431!5m2!1sen!2sin" width="100%" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </main>
  )
}

export default Contactmap
