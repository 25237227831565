import React, { useEffect, useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCity, faLocationDot, faPhone, faEnvelope } from '@fortawesome/free-solid-svg-icons'
import ReCAPTCHA from "react-google-recaptcha";
import 'aos/dist/aos.css';
import { api } from '../Constants';
import AOS from 'aos';

function Formsec() {
    function onChange(value) {
        console.log("Captcha value:", value);
    }
    useEffect(() => {
        AOS.init({
            duration: 1200,
            once: false,
        });
    }, []);

    const [name, setName] = useState("");

    const [mobile, setMobile] = useState("");

    const [email, setEmail] = useState("");

    const [city, setCity] = useState("");

    const [message, setMessage] = useState("");

    // var subject = "testing";
    let nameerror = /^[a-zA-Z]{3,30}$/.test(name);
    let numbererror = /^\+?([0-9]{2})\)?[-. ]?([0-9]{4})[-. ]?([0-9]{4})$/.test(mobile);
    let emailrerror = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(email);

    const handleClick = async () => {

        if (name === "") {
            document.getElementById("nameerror").innerHTML = 'Name is required'
        }
        else if (!nameerror) {
            document.getElementById("nameerror").innerHTML = 'Enter a valid name'
        }
        else {
            document.getElementById("nameerror").innerHTML = ''
        }

        //number valid
        if (mobile === "") {
            document.getElementById("numbererror").innerHTML = 'Number is required'
        }
        else if (!numbererror) {
            document.getElementById("numbererror").innerHTML = 'Enter a valid number'
        }
        else {
            document.getElementById("numbererror").innerHTML = ''
        }
        //email valid errormessage
        if (email === "") {
            document.getElementById("emailerror").innerHTML = 'Email is required'
        }
        else if (!emailrerror) {
            document.getElementById("emailerror").innerHTML = 'Enter a valid email'
        }
        else {
            document.getElementById("emailerror").innerHTML = ''
        }
        //message valid
        // city error
        if (city === "") {
            document.getElementById("cityerror").innerHTML = 'City is required'
        }
        else {
            document.getElementById("cityerror").innerHTML = '';
        }
        // city error
        if (message === "") {
            document.getElementById("errormessage").innerHTML = 'Message is required'
        }
        else {
            document.getElementById("errormessage").innerHTML = ""
        }
        if (nameerror && numbererror && emailrerror && message) {
            const dataTosubmit = {
                name,
                email,
                mobile,
                message,
                city,
            };
            fetch(api + "add_enquirydetails", {
                method: "POST",
                headers: {
                    Accept: "aplication/json",
                    "Content-Type": "application/json;charset=UTF-8",
                },
                body: JSON.stringify(dataTosubmit),
            })
                .then((res) => res.json())
                .then((res) => {
                    if (res.status === 200) {
                        setName("")
                        setMobile("")
                        setEmail("")
                        setMessage("")
                        window.location.reload();
                    }
                    else {
                        alert("Some error occured");
                    }
                });
        }
    }
    return (
        <div class="pt-80 pb-40 contact-area section-space" id="contact-section-id">
            <div class="container">
                <div class="row" data-aos="fade-up">
                    <div class="col-lg-6">
                        <div class="contact-info">
                            <h2>Any Query, Please Contact Us</h2>
                            <p>Thanks for Showing interest in our products, We are always happy to serve you, Feel free to contact us through the details we have provided, we will update you as soon as possible.</p>
                            <ul class="contact-address">
                                <li><FontAwesomeIcon icon={faCity} className='icon' />DIX TECHNOLOGIES</li>
                                <li><FontAwesomeIcon icon={faLocationDot} className='icon' /> <a href="https://www.google.com/maps/search/ID,+Ramaniyam+Pushkar+Phase2,+Sholinganallur,+Chennai-600119./@12.9005415,80.2343705,19z?authuser=0&entry=ttu" target="_blank">ID, Ramaniyam Pushkar Phase2, Sholinganallur, Chennai-600119.</a> </li>
                                <li><FontAwesomeIcon icon={faPhone} className='icon' /> <a href="callto:8531951085" class="me-2">+91 8531951085 </a></li>
                                <li class="mail-word-break"><FontAwesomeIcon icon={faEnvelope} className='icon' /><a href="mailto:info@flashm.co.in">info@flashm.co.in</a></li>
                            </ul>
                        </div>
                    </div>
                    <div class="col-lg-6" id='contactform'>
                        <div class="contact-message">
                            <h2>Contact Us</h2>
                            <form action='javascript:void(0);' class="contact-form">
                                <div class="row">
                                    <div class="col-lg-6 col-md-6 col-sm-6 pad">
                                        <input type="text"
                                            name="name" id="name"
                                            placeholder='Your Name'
                                            onChange={(event) => setName(event.target.value)}
                                            autocomplete="off"
                                            required=""
                                            onKeyPress={(event) => {
                                                if (!/[a-z,A-Z]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                        <small id='nameerror'></small>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 pad">
                                        <input name="mobile"
                                            placeholder='Your Phone No'
                                            maxlength="10" id="mobile"
                                            autocomplete="off"
                                            onChange={(event) => setMobile(event.target.value)}
                                            rquired=""
                                            onKeyPress={(event) => {
                                                if (!/[0-9]/.test(event.key)) {
                                                    event.preventDefault();
                                                }
                                            }} />
                                        <small id='numbererror'></small>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 pad">
                                        <input type="text"
                                            placeholder='Your e-mail'
                                            name="email"
                                            id="email"
                                            autocomplete="off"
                                            required=""
                                            onChange={(event) => setEmail(event.target.value)} />
                                        <small id='emailerror'></small>
                                    </div>
                                    <div class="col-lg-6 col-md-6 col-sm-6 pad">
                                        <input type="text"
                                            placeholder='City'
                                            name="city"
                                            id="city"
                                            autocomplete="off"
                                            required=""
                                            onChange={(event) => setCity(event.target.value)} />
                                        <small id='cityerror'></small>
                                    </div>
                                    <div class="col-12 pad">
                                        <div class="contact2-textarea">
                                            <textarea type="text" name="message"
                                                placeholder='Your message'
                                                id="message"
                                                autocomplete="off"
                                                required=""
                                                onChange={(event) => setMessage(event.target.value)}></textarea>
                                            <small id='errormessage'></small>
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="captcha">
                                            <ReCAPTCHA
                                                sitekey={"6Lep_JUpAAAAAOvfRkcPYCa5WmOdfTLZWp3pmS1r"}
                                                Secret Key={"6Lep_JUpAAAAAJP3BdXytx8ga3Fk8a15QZkD6Pew"}
                                                onChange={onChange} />,
                                        </div>
                                    </div>
                                    <div class="col-12">
                                        <div class="contact-btn">
                                            <button class="btn btn__bg" type="submit" onClick={handleClick}>Send Message</button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Formsec
